import { start, registerApplication } from 'single-spa';
import 'babel-polyfill';
import { envVars } from './envVars';
import { defaultCustomProps } from '.';

if (!localStorage.getItem('greencare-access-token')) {
  localStorage.setItem('greencare-access-token', 'local-token');
}

registerApplication(
  envVars.APP_NAME,
  (window as any).System.import('/funding/cnab.js'),
  location => location.pathname.startsWith('/preview'),
  {
    ...defaultCustomProps,
    basePath: '/preview',
  },
);

if (!window.location.pathname || window.location.pathname === '/') {
  (window as any).singleSpaNavigate('/preview');
}

start();
